<template>
	<div class="LevelDescriptionShare">
		<w-navTab titleText="义警服务证"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container" id="NoticeDetail">
			<div class="header-content">
				<div class="header-content-title">
					<div class="header-title-icon">
						<img src="@/assets/img/u_icon104.png" alt="">
					</div>
					<div class="header-title-right">
						<div class="title-right-title">义警服务证</div>
						<div class="title-right-text">{{userInfo.district_name}}平安义警协会</div>
					</div>
				</div>
				<div class="header-content-middle">
					<div class="header-middle-item">
						<div class="middle-item-lable">姓名/Name</div>
						<div class="middle-item-text">{{ userInfo.name }}</div>
					</div>
					<div class="header-middle-item">
						<div class="middle-item-lable">性别/Sex</div>
						<div class="middle-item-text">{{userInfo.sex}}</div>
					</div>
					<div class="header-middle-item">
						<div class="middle-item-lable">地区/Add</div>
						<div class="middle-item-text">{{userInfo.district_name}}</div>
					</div>
				</div>
				<div class="header-content-middle">
					<div class="header-middle-item" v-if="userInfo.member_level != '' && userInfo.role_type == 3">
						<div class="middle-item-lable">职衔/Rank</div>
						<div class="middle-item-text">{{userInfo.member_level}}</div>
					</div>
					<div class="header-middle-item">
						<div class="middle-item-lable">义警编号/No.</div>
						<div class="middle-item-text lable-active">{{userInfo.police_number}}</div>
					</div>
				</div>

				<div class="headert-content-right">
					<div class="right-avatar-img">
						<img :src="userInfo.avatar" alt="" />
					</div>
					<div class="right-avatar-text">{{userInfo.duty}}</div>
				</div>
			</div>
			<div class="header-centre">
				<div class="header-centre-icon">
					<img src="@/assets/img/u_icon104.png" alt="">
				</div>
				<div class="header-centre-title">
					奉献 友爱 互助 进步
				</div>
				<div class="header-centre-time">
					有效期：{{validTime.start_ime}} 至 {{validTime.end_time}}
				</div>
			</div>
			<div class="body-content">
				<div class="body-content-img">
					<img src="@/assets/img/u_icon114.png" alt="">
				</div>
				<div class="body-content-text">扫码进入</div>
			</div>
		</div>
		<div class="botton">
			<div class="botton-text" @click="convertDivToImage">分享义警服务证</div>
			<!-- 请先保存相册，然后分享朋友圈。 -->
		</div>
		<van-popup v-model="popupShow">
			<div class="popup-img">
				<img :src="popupImg" alt="">
			</div>
			<div class="popup-footer" @click="onDownload">
				<div class="popup-footer-icon">
					<img src="@/assets/img/u_icon124.png" alt="">
				</div>
				<div class="popup-footer-text">保存图片</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import html2canvas from 'html2canvas'
	import User from '@/api/user';
	import LoadingPage from '@/components/LoadingPage';
	import Config from '@/vendor/config';
	import VantVendor from '@/vendor/vant';
	import axios from 'axios';
	
	export default {
		name: 'LevelDescriptionShare',
		data() {
			return {
				userInfo: '',
				validTime: '',
				loadingPage: true,
				popupImg: '',
				popupShow: false
			}
		},
		created() {
			User.getUserBasicInfo()
				.then(result => {
					this.userInfo = result.data;
					this.validTime = result.data.valid_time
					this.loadingPage = false;
					
					let img = ''
					
					if(result.data.police_photo){
						img = result.data.police_photo
					}else{
						img = result.data.avatar
					}
					
					this.getImageInfo(img)
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		},
		methods: {
			//转换图片，传入元素id
			convertDivToImage() {
				VantVendor.Toast.loading('请先保存图片到相册，然后分享朋友圈...');
				this.htmlTocanvas()
			},
			getBase64(url) {
				this.popupImg = url
				this.popupShow = true
			},
			htmlTocanvas() {
				var canvas2 = document.createElement("canvas");
				let _canvas = document.getElementById("NoticeDetail");
				var w = parseInt(window.getComputedStyle(_canvas).width);
				var h = parseInt(window.getComputedStyle(_canvas).height);
				canvas2.width = w * 3;
				canvas2.height = h * 3;
				canvas2.style.width = w + "px";
				canvas2.style.height = h + "px";
				canvas2.style.objectFit = 'cover'
				var context = canvas2.getContext("2d");
			
				html2canvas(document.getElementById("NoticeDetail"), {
					canvas: canvas2
				}).then(canvas => {
					this.getBase64(canvas.toDataURL("image/png"));
					VantVendor.Toast.clear();
				});
			},
			getImageInfo(path) {
				return new Promise((resolve, reject) => {
					axios.get(path, {
							responseType: 'blob'
						})
						.then(res => {
							const blob = res.data
							const url = URL.createObjectURL(blob)
							const img = new Image()
							img.src = url
							this.userInfo.avatar = url
							img.onload = () => {
								resolve(img)
							}
						})
						.catch(() => {
							console.log('加载图片失败')
							reject(new Error('加载图片失败'))
						})
				})
			},
			// 下载图片
			onDownload() {
				this.savePicture(this.popupImg);
			},
			savePicture(Url) {
				var triggerEvent = "touchstart"; //指定下载方式
				var blob = new Blob([''], {
					type: 'application/octet-stream'
				}); //二进制大型对象blob
				var url = URL.createObjectURL(blob); //创建一个字符串路径空位
				var a = document.createElement('a'); //创建一个 a 标签
				a.href = Url;
				a.download = Url.replace(/(.*\/)*([^.]+.*)/ig, "$2").split("?")[0];
				var e = new MouseEvent('click', (true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null));
				a.dispatchEvent(e);
				URL.revokeObjectURL(url);
			},
		
		},
		components:{
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.LevelDescriptionShare {
		width: 100%;
		min-height: 100vh;
		
		.container {
			width: 100%;
			height: 812px;
			background-image: url('../../assets/img/u_icon110.png');
			background-size: cover;
			padding: 80px 16px 0;
			box-sizing: border-box;
			
			.header-content {
				padding: 14px 16px;
				width: 343px;
				height: 196px;
				background-image: url('../../assets/img/u_icon111.png');
				background-size: cover;
				box-sizing: border-box;
				position: relative;

				.header-content-title {
					display: flex;
					align-items: center;

					.header-title-icon {
						width: 42px;
						height: 42px;
						min-width: 42px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.header-title-right {
						padding-left: 8px;

						.title-right-title {
							color: #020917;
							font-size: 17px;
							font-weight: bold;
							line-height: 20px;
						}

						.title-right-text {
							color: #333;
							margin-top: 4px;
							font-size: 12px;
						}
					}
				}

				.header-middle-item {
					margin-right: 24px;

					.middle-item-lable {
						color: #333;
						font-size: 12px;
						line-height: 18px;
					}

					.middle-item-text {
						color: #020917;
						font-size: 15px;
						margin-top: 4px;
						font-weight: bold;
						line-height: 23px;

						&.lable-active {
							font-size: 18px;
						}
					}
				}

				.header-content-middle {
					margin: 16px 0 12px;
					display: flex;
					align-items: center;
				}

				.headert-content-right {
					position: absolute;
					top: 32px;
					right: 20px;


					.right-avatar-img {
						width: 63px;
						height: 86px;
						position: absolute;
						top: 0;
						right: 0;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.right-avatar-text {
						color: #FFF;
						font-size: 14px;
						line-height: 16px;
						margin-top: 94px;
						text-align: center;
					}

				}
			}
			
			.header-centre{
				width: 343px;
				height: 196px;
				background-image: url('../../assets/img/u_icon112.png');
				background-size: cover;
				margin-top: 16px;
				padding-top: 39px;
				box-sizing: border-box;
				text-align: center;
				
				.header-centre-icon{
					width: 42px;
					height: 42px;
					margin: 0 auto;
					
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.header-centre-title{
					color: #020917;
					font-size: 20px;
					line-height: 24px;
					margin-top: 16px;
				}
				.header-centre-time{
					margin-top: 8px;
					color: #333;
					font-size: 13px;
					line-height: 20px;
				}
			}
		
			.body-content{
				margin-top: 144px;
				.body-content-img{
					width: 88px;
					height: 88px;
					margin: 0 auto;
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.body-content-text{
					color: #FFF;
					text-align: center;
					font-size: 16px;
					line-height: 24px;
					margin-top: 10px;
				}
			}
		}
	
		.botton {
			width: 100%;
			position: fixed;
			left: 0;
			bottom: 10px;
			padding: 0 70px;
			box-sizing: border-box;
		
			.botton-text {
				padding: 8px 0;
				text-align: center;
				color: #F7F7F9;
				font-size: 16px;
				border-radius: 4px;
				border: 1px solid #3E5CB0;
				background: #0E38AD;
				box-sizing: border-box;
				line-height: 24px;
			}
		}
	
		/deep/.van-popup {
			background-color: initial;
		}
		
		.popup-img {
			width: 300px;
			height: 100%;
			margin: 0 auto;
		
			img {
				width: 300px;
				height: 100%;
				object-fit: cover !important;
			}
		}
		
		.popup-footer {
			
			.popup-footer-icon{
				width: 24px;
				height: 24px;
				margin: 0 auto;
				
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.popup-footer-text{
				text-align: center;
				color: #FFF;
				font-size: 12px;
				line-height: 18px;
			}
		}
	}
</style>